const currencyUnits: any = {
  NPR: 'Rs.',
  USD: '$'
};

export const makeDisplayAmount = (currency: {
  currencyCode: string;
  currencyValue: string | number;
}) => {
  if (!currency) {
    return null;
  }
  try {
    const displayAmount = `${currencyUnits[currency.currencyCode]}${
      currency.currencyValue
    }`;
    return displayAmount;
  } catch (error) {
    throw new Error(`currencyCode or currencyValue Error: ${error}`);
  }
};
