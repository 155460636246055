import * as React from 'react';

interface RadioBoxIProps {
  items: string[];
  selectedItem: string | null;
  onChange: (value: string) => void;
}

function RadioBox({ items = [], selectedItem, onChange }: RadioBoxIProps) {
  return (
    <div className="flex flex-wrap">
      {items.map((provider: string) => {
        const isSelected = selectedItem === provider;
        return (
          <label
            key={provider}
            className={`w-48 h-24 p-2 flex items-center cursor-pointer shadow hover:bg-primary hover:bg-opacity-25 rounded my-1 mr-4 transition ease-in duration-300 ${
              isSelected ? 'border border-primary' : ''
            }`}
          >
            <div className="bg-white shadow border border-gray-500 w-4 h-4 p-2 flex justify-center items-center mr-4 rounded-full">
              <input
                type="checkbox"
                className="hidden"
                checked={isSelected}
                onChange={() => onChange(provider)}
              />
              {isSelected && (
                <div className="flex justify-center items-center w-4 h-4 p-2 border border-primary float-right rounded-full">
                  <span className="inline-block p-1 bg-primary rounded-full"></span>
                </div>
              )}
            </div>
            <span className="text-sm select-none">{provider}</span>
          </label>
        );
      })}
    </div>
  );
}

export default RadioBox;
