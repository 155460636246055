import React from 'react';
import { navigate } from '@reach/router';
import InputSelect from './inputSelect';
import { FullPageSpinner } from '../../../components/spinner';
import { apiPost } from '../../../api/method';
import RadioBox from './radioBox';
import FormData from './formData';
import {
  paymentProviderType,
  usePaymentProvider
} from '../../../context/PaymentProvider';
import ModalError from '../../../components/modal/ModalError';

const CommonPayment: React.FC<{ urlParameters: any }> = ({ urlParameters }) => {
  const {
    providers,
    selectedProvider,
    setSelectedProvider,
    selectedBank,
    setSelectedBank
  }: any = usePaymentProvider();
  const [isLoading, setIsLoading] = React.useState(false);
  const [onError, setOnError] = React.useState(false);

  const [formData, setFormData] = React.useState<any>(null);
  const formRef = React.useRef<any>();

  React.useEffect(() => {
    if (formRef.current && formData) {
      formRef.current.submit();
    }
  }, [formData]);

  const hasUrlParameters = Object.keys(urlParameters).length > 0;

  const { pId, pType, bId, spId, q, brId }: any = urlParameters;

  React.useEffect(() => {
    if (!hasUrlParameters && localStorage.getItem('persist-trx-url')) {
      navigate(`${localStorage.getItem('persist-trx-url')}`, {
        replace: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (q === 'failure') {
      setOnError(true);
      navigate(`${localStorage.getItem('persist-trx-url')}`, {
        replace: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q]);

  const onHandlePay = async () => {
    try {
      setOnError(false);
      setIsLoading(true);
      const { data }: any = await apiPost('/payments/intent', {
        provider: selectedProvider,
        productId: pId,
        productEntity: pType,
        bookingId: bId,
        serviceProviderId: spId,
        bookableResourceId: brId
      });
      debugger;
      setFormData(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setOnError(true);
    }
  };
  let currProviderBanks: any = [];
  if (selectedProvider && providers) {
    const foundProvider = providers.find(
      (provider: any) => provider.provider === selectedProvider
    );
    if (foundProvider?.banks) {
      currProviderBanks = foundProvider.banks;
    }
  }

  const showPayButton =
    (providers.length && selectedProvider === paymentProviderType.ESEWA) ||
    (providers.length && selectedBank && selectedBank.entityCode);

  return (
    <div className="mx-auto mb-16">
      {isLoading && <FullPageSpinner />}
      <ModalError show={onError} setShow={() => setOnError(!onError)} />
      {providers.length && (
        <>
          <div className="text-sm font-medium mt-10 mb-3">
            Choose your preferred payment method:
          </div>
          <div className="flex items-center flex-wrap mb-5">
            <RadioBox
              items={providers.map((provider: any) => provider.provider)}
              selectedItem={selectedProvider}
              onChange={(provider: string) => {
                if (provider !== selectedProvider) {
                  setSelectedProvider(provider);
                  setSelectedBank(null);
                }
              }}
            />
          </div>
        </>
      )}
      {currProviderBanks.length > 0 && (
        <div className="flex items-center flex-wrap">
          <span className="text-sm font-medium w-20">Bank:</span>
          <InputSelect
            onChange={(bank: string) => setSelectedBank(bank)}
            selectedOption={selectedBank}
            options={currProviderBanks}
          />
        </div>
      )}
      {formData && (
        <FormData ref={formRef} url={formData.postUrl} data={formData.data} />
      )}
      {showPayButton && (
        <button
          onClick={onHandlePay}
          className="flex items-center justify-center text-white bg-primary rounded px-4 py-2 mt-10 hover:bg-opacity-75 focus:outline-none transition ease-in duration-300"
        >
          <i className="material-icons text-sm pr-2">lock</i>
          <span className="text-sm font-bold pr-1">PAY NOW</span>
          <span className="text-sm">with {selectedProvider}</span>
        </button>
      )}
    </div>
  );
};

export default CommonPayment;
