import React from 'react';

const SuccessMessage: React.FC<{
  timer: number;
  redirectToOrigin: () => void;
}> = ({ timer, redirectToOrigin }) => {
  const [countdown, setCountdown] = React.useState<number>(timer);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((countdown) => countdown - 1000);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const inSeconds = countdown < 0 ? 0 : countdown / 1000;
  return (
    <div className="container mx-auto mb-16">
      <h1 className="mt-20 text-center font-medium text-lg">Thank You</h1>
      <i className="block material-icons text-center text-4xl text-primary my-8">
        check
      </i>
      <div className="mt-8 text-center font-medium text-lg">
        Payment was successful
      </div>
      <div className="mt-8 text-center font-medium text-sm">
        Redirecting you to the origin in…
      </div>
      <div className="mt-4 text-primary text-center font-bold text-4xl">
        {inSeconds}
      </div>
      <div className="flex items-center justify-center">
        <button
          onClick={redirectToOrigin}
          className="text-white bg-primary rounded px-4 py-2 mt-10 hover:bg-opacity-75 focus:outline-none transition ease-in duration-300"
        >
          <span className="text-sm font-bold pr-1">Return to booking</span>
        </button>
      </div>
    </div>
  );
};

export default SuccessMessage;
