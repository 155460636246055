import React from 'react';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

function InputSelect({ onChange, options = [], selectedOption }: any) {
  const [selected, setSelected] = React.useState<any>(selectedOption);

  React.useEffect(() => {
    !selectedOption &&
      setSelected({ entityName: 'Select bank', entityCode: '' });
  }, [selectedOption]);

  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef<any>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => onChange(selected), [selected]);

  useOnClickOutside(ref, () => setIsOpen(false));
  return (
    <div ref={ref} className="w-56 rounded">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="relative flex items-center justify-between border border-gray-500 h-8 px-1 rounded focus:outline-primary focus:border-primary hover:bg-primary hover:bg-opacity-25 w-56"
      >
        <span className="text-sm truncate">
          {selected && selected.entityName}
        </span>
        {isOpen ? (
          <i className="material-icons text-lg float-right fill-current">
            expand_less
          </i>
        ) : (
          <i className="material-icons text-lg float-right fill-current">
            expand_more
          </i>
        )}
      </button>
      {isOpen && (
        <div className="absolute bg-white rounded shadow-md my-1 w-56">
          <ul
            className="list-reset overflow-y-auto"
            style={{ maxHeight: '12rem' }}
          >
            {options.map((option: any) => (
              <li
                key={option.entityName}
                onClick={() => {
                  setSelected(option);
                  setIsOpen(false);
                }}
                className="flex items-center justify-between p-2 text-black hover:bg-primary hover:bg-opacity-25 cursor-pointer transition ease-in duration-300"
              >
                <span className="text-sm truncate">{option.entityName}</span>
                {option.name === selected.entityName && (
                  <i className="material-icons text-sm">check</i>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default InputSelect;
