import React from 'react';
import { parse } from 'query-string';
import asyncHelper from '.././api/helper';
import { apiGet } from '.././api/method';
import { FullPageSpinner } from '../components/spinner';

export enum paymentProviderType {
  ESEWA = 'eSewa',
  NPAY = 'nPay',
  STRIPE = 'stripe'
}

export type PaymentProviderContextType = {
  providers: any[];
  selectedProvider: string | null;
  setSelectedProvider: (provider: string) => void;
  selectedBank: string;
  setSelectedBank: (bank: string) => void;
  currency: null | any;
};

const PaymentProviderContext = React.createContext<string | null>(null);

export function PaymentProvider(props: any) {
  const [providers, setProviders] = React.useState<any | null>([]);
  const [selectedProvider, setSelectedProvider] = React.useState<string | null>(
    localStorage.getItem('paymentProvider') || null
  );
  const [selectedBank, setSelectedBank] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const [currency, setCurrency] = React.useState<any>(null);

  async function fetchProviders() {
    setIsError(false);
    setIsLoading(true);
    const [error, data] = await asyncHelper(apiGet('/payments/providers'));
    setIsLoading(false);
    if (error) {
      setIsError(true);
    } else {
      // currently supports esewa
      setProviders(
        data.data.filter(
          (paymentProvider: any) =>
            paymentProvider.provider === paymentProviderType.ESEWA
        )
      );
    }
  }

  React.useEffect(() => {
    if (selectedProvider) {
      localStorage.setItem('paymentProvider', selectedProvider);
    }
  }, [selectedProvider]);

  React.useEffect(() => {
    if (/verify/i.test(window.location.href)) {
      setIsLoading(false);
      return;
    }

    if (isForeignTimeZone()) {
      initForeignCurrency();
    } else {
      initLocalCurrency();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function isForeignTimeZone() {
    // const nepalTimeZoneOffset = -345;
    // const currentTimeZoneOffset = new Date().getTimezoneOffset();
    // return currentTimeZoneOffset !== nepalTimeZoneOffset;
    return false;
  }

  function getUrlPrice() {
    const urlParams: string = window.location.search.substring(1);
    const { price = 0 } = parse(urlParams);
    return price;
  }

  async function initForeignCurrency() {
    const urlPrice = getUrlPrice();
    const [error, data] = await asyncHelper(
      apiGet(`/currency/amount?amount=${urlPrice}&currencyCode=USD`)
    );
    if (error) {
      setIsError(true);
      setIsLoading(false);
      return;
    }
    setCurrency(data.data);
    setSelectedProvider(paymentProviderType.STRIPE);
    setIsLoading(false);
  }

  function initLocalCurrency() {
    const urlPrice = getUrlPrice();
    const localPaymentProvider = localStorage.getItem('paymentProvider');
    const currrentPaymentProvider =
      localPaymentProvider &&
      localPaymentProvider !== paymentProviderType.STRIPE
        ? localPaymentProvider
        : paymentProviderType.ESEWA;
    setCurrency({ currencyCode: 'NPR', currencyValue: urlPrice });
    setSelectedProvider(currrentPaymentProvider);
    fetchProviders();
  }

  if (isLoading) {
    return <FullPageSpinner />;
  }

  if (isError) {
    return (
      <div className="container mx-auto mb-16">
        <div className="text-sm font-medium text-error mb-5">
          Oops! something went wrong. Please try again!.
        </div>
      </div>
    );
  }
  const contextValue: PaymentProviderContextType = {
    providers,
    selectedProvider,
    setSelectedProvider,
    selectedBank,
    setSelectedBank,
    currency
  };
  if (selectedProvider) {
    return <PaymentProviderContext.Provider value={contextValue} {...props} />;
  }

  throw new Error('Unhandled error: PaymentProvider');
}

export function usePaymentProvider() {
  const context = React.useContext(PaymentProviderContext);
  if (context === undefined) {
    throw new Error(`usePaymentProvider must be used within a PaymentProvider`);
  }
  return context;
}
