import React from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from '@stripe/react-stripe-js';
import './stripePayment.css';
import useResponsiveFontSize from '../../../hooks/useResponsiveFontSize';
import { FullPageSpinner } from '../../../components/spinner';
import ModalError from '../../../components/modal/ModalError';
import { apiPost } from '../../../api/method';
import { usePaymentProvider } from '../../../context/PaymentProvider';
import { handleAndroidPaymentStatus } from '../../verifyPayment';
import CardsImages from './CardsImages';

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = React.useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#ff0000'
        }
      }
    }),
    [fontSize]
  );

  return options;
};

const cardFields = [
  {
    label: 'Card number',
    fieldName: 'cardNumber',
    component: CardNumberElement
  },
  {
    label: 'Expiration data',
    fieldName: 'cardExpiry',
    component: CardExpiryElement
  },
  {
    label: 'CVC',
    fieldName: 'cardCvc',
    component: CardCvcElement
  }
];

const StripeForm = ({ urlParameters, setSuccess }: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [cardFieldErrors, setCardFieldErrors] = React.useState<any>({
    cardNumber: null,
    cardExpiry: null,
    cardCvc: null
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<null | any>(null);
  const { selectedProvider }: any = usePaymentProvider();

  function onChangeCardInput(e: any) {
    setCardFieldErrors((prevState: any) => ({
      ...prevState,
      [e.elementType]: e.error
    }));
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    try {
      setError(null);
      setLoading(true);
      const { paymentMethod, error }: any = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement) as any
      });
      if (error) {
        setLoading(false);
        return;
      }
      const { data } = await apiPost('/payments/intent', {
        provider: selectedProvider,
        productId: urlParameters.pId,
        productEntity: urlParameters.pType,
        bookingId: urlParameters.bId,
        serviceProviderId: urlParameters.spId,
        stripePaymentId: paymentMethod.id
      });
      await apiPost(
        `/payments/verifyTransaction?stripeIntentId=${data.stripeIntentId}&provider=${selectedProvider}&trxId=${data.trxId}`,
        {}
      );
      handleAndroidPaymentStatus(true);
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      handleAndroidPaymentStatus(false);
      setLoading(false);
      setError({ message: error.message });
    }
  };

  return (
    <div className="stripePayment">
      {loading && <FullPageSpinner />}
      <ModalError
        show={error}
        message="Please try again ina moment."
        setShow={() => setError(null)}
      />
      <div className="flex flex-wrap mt-6 mb-3">
        <h2 className="text-lg font-semibold mr-4">Pay with card</h2>
        <CardsImages />
      </div>
      <form
        onSubmit={handleSubmit}
        className="bg-white shadow border border-gray-200 px-2 lg:px-12 py-4 lg:py-8 rounded-md"
      >
        {cardFields.map(({ label, fieldName, component: Component }) => (
          <label key={fieldName} className="text-sm">
            {label}
            <Component options={options} onChange={onChangeCardInput} />
            {cardFieldErrors[fieldName] && (
              <p className="text-error mb-2">
                {cardFieldErrors[fieldName].message}
              </p>
            )}
          </label>
        ))}
        <button
          type="submit"
          disabled={!stripe || loading}
          className="flex items-center justify-center text-white bg-primary rounded px-4 py-2 mt-10 hover:bg-opacity-75 focus:outline-none transition ease-in duration-300"
        >
          <i className="material-icons text-sm pr-2">lock</i>
          <span className="text-sm font-bold pr-1">PAY NOW</span>
        </button>
      </form>
    </div>
  );
};

export default StripeForm;
